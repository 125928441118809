/*Desktop*/
.Header {
    max-width: 100%;
    overflow: hidden;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: #98d9c6;
}

.HeaderContent {
    width: 1024px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.main {
    width: 50%;
    height: 100%;
    float: left;
}

.mainImage {
    max-width: 50%;
    margin-bottom: 0;
    height: auto;
    float: right;
    overflow: hidden;
    filter: saturate(100%) opacity(70%);
}

.mainImageContentHeader {
    font-size: 50px;
}

.mainImageContentBody {
    font-size: 18px;
    line-height: 28px
}

.boxRow {
    width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 50px auto auto auto;
    column-gap: 22px;
}

.Box {
    font-family: 'Open Sans', sans-serif;
    width: 326px;
    display: inline-flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 1px solid #d8d8d8;
    min-height: 630px;
    margin-bottom: 30px;
}

.BoxImage {
    width: 100%;
}

.BoxTitle {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: black;
    padding-left: 10px;
    margin-bottom: 0;
}

.BoxDate {
    padding-left: 10px;
    margin-bottom: 0;
}

.BoxContent {
    font-size: 14px;
    color: #666;
    line-height: 1.7em;
    padding: 0px 10px 0px 10px;
}

.BoxLink {
    align-self: self-end;
    margin-top: auto;
    color: #2ea3f2;
    text-decoration: none;
    padding: 0px 20px 20px 0px;
}

/*Mobil*/
.HeaderMobil {
    max-width: 100%;
    overflow: hidden;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: #98d9c6;
}

.HeaderContentMobil {
    width: 90%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.mainMobil {
    width: 100%;
}

.mainImageMobil {
    width: 100%;
    margin-bottom: 0;
    height: auto;
    float: right;
    overflow: hidden;
    filter: saturate(100%) opacity(70%);
}

.mainImageContentHeaderMobil {
    font-size: 2rem;
}

.mainImageContentBodyMobil {
    font-size: 1rem;
    line-height: 1.7rem;
}

.boxRowMobil {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: auto auto auto auto;
    column-gap: 0;
}

.BoxMobil {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    display: inline-flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 1px solid #d8d8d8;
    margin: 2rem 0 0 0;

}

.BoxMobil:last-child {
    margin: 2rem 0 5rem 0;

}

.BoxImageMobil {
    width: 100%;
}

.BoxTitleMobil {
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: left;
    color: black;
    padding-left: 0.5rem;
    margin-bottom: 0;
}

.BoxDateMobil {
    padding-left: 0.5rem;
    margin-bottom: 0;
}

.BoxContentMobil {
    font-size: 1rem;
    color: #666;
    line-height: 1.7rem;
    padding: 0 0.5rem 0 0.5rem;
}

.BoxLinkMobil {
    align-self: self-end;
    margin-top: auto;
    color: #2ea3f2;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0 0.5rem 0.5rem 0;
}