/*Desktop*/
/* .FirstLevelmainImageContainer {    
    width: 100%;
    overflow: hidden;
}
.FirstLevelmainImage {
     width: 100%;
} 

.FirstLevelMessage {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 36px;
    color: #4d7d97;
    letter-spacing: 4px;
    text-align: center;
   }

.FirstLevelboxRow {
    width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 50px auto auto auto;
    column-gap: 22px;
    margin-bottom: 30px;
}

.FirstLevelBox {  
    font-family: Open Sans, sans-serif;
    width: 326px;
    display: inline-flex;
    justify-content: start;
    align-items: start; 
    flex-direction: column;
    margin-bottom: 22px;
}

.FirstLevelBoxImage {
    width: 100%;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
}

.FirstLevelBoxTitle {  
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: black;
    margin-top: 30px;
    margin-bottom: 0;
}

.FirstLevelBoxContent {
    font-size: 14px;
    color: #666;
    line-height: 1.7em;
    padding: 0px 10px 0px 0;    
}

.FirstLevelBoxLink {    
    margin-top: auto;
    color: #2ea3f2;
    text-decoration: none;
    padding: 0px 20px 20px 0px;
}

.Back{
    position: fixed;
    bottom: 0;
    left: 0;
} */

.searchbox{
    width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 50px auto auto auto;    
}

.results{
    width: 1024px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 50px auto auto auto;
    column-gap: 22px;
    margin-bottom: 30px;
}

.results a{
    text-transform: uppercase;
}

a.more-link {
    display:none;
}

.searchInput{
    margin-left: 10px;
}

/*Mobil*/
.searchboxMobil{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 2rem auto auto auto;    
}

.resultsMobil{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    margin: 1rem auto auto auto;    
}

.searchInputMobil{
    margin-left: 1rem;
    width: 50%;
}
/*


.FirstLevelmainImageContainerMobil {    
    width: 100%;
    overflow: hidden;
}
.FirstLevelmainImageMobil {
     width: 100%;
} 

.FirstLevelMessageMobil {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.6rem;
    color: #4d7d97;
    letter-spacing: 0.2rem;
    text-align: center;
   }

.FirstLevelboxRowMobil {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    column-gap: 0.1rem;
    margin-bottom: 0.3rem;
}

.FirstLevelBoxMobil {  
    font-family: Open Sans, sans-serif;
    width: 90%;
    display: inline-flex;
    justify-content: start;
    align-items: start; 
    flex-direction: column;
    margin-bottom: 2.5rem;
}

.FirstLevelBoxMobil:last-child {      
    margin-bottom: 20.5rem;
}

.FirstLevelBoxImageMobil {
    width: 100%;
    box-shadow: 0 0.1rem 0.3rem 0 rgb(0 0 0 / 30%);
}

.FirstLevelBoxTitleMobil {  
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: left;
    color: black;
    margin-top: 3rem;
    margin-bottom: 0;
}

.FirstLevelBoxContentMobil {
    font-size: 1rem;
    color: #666;
    line-height: 1.7rem;
    padding: 0 0.1rem 0 0;    
}

.FirstLevelBoxLinkMobil {    
    margin-top: auto;
    color: #2ea3f2;
    text-decoration: none;
    font-size: 1.4rem;
    padding: 0 2rem 0.8rem 0;
}

.FirstLevelHRMobile{
    width: 100%;
} */