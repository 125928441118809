/*Desktop*/
.BalesetiBack{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 155px;
    height: 100px;
    background-image: url('./first_level_corner_baleseti.png');
    background-repeat: no-repeat;
    background-size:cover;    
}

.BackLink{
    position: absolute;
    bottom: 6%;
    left: 10%;
    font-size: 20px;
    color: #4fbe9f;
    cursor: pointer;
}

/*Mobil*/
.BalesetiBackMobile{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 12rem;
    height: 7rem;
    background-image: url('./first_level_corner_baleseti.png');
    background-repeat: no-repeat;
    background-size:cover;    
}

.BackLinkMobile{
    position: absolute;
    bottom: -0.5rem;
    left: 1.5rem;
    font-size: 1.8rem;
    color: #4fbe9f;
    cursor: pointer;
}