/*Desktop*/
.ArticleContainer {
    font-family: 'Open Sans', sans-serif;
    width: 40%;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    color: #666;
    line-height: 1.9em;
    font-weight: 400;
}

.ArticleContainer h1 {
    color: black;
    font-size: 24px;
    line-height: 32px;
}

.wp-block-table table,
th,
td {
    border: solid 1px #666;
    border-collapse: collapse;
    text-align: center;
}

.ArticleContainer p {
    font-size: 16px;
}

.ArticleFeaturedImage {
    width: 100%;
    box-shadow: 2px 5px 16px 0px #222222;    
}

.ArticleContainer img {
    max-width: 100%;
    min-width: 300px;
    margin: auto auto;
    margin-bottom: 50px;
}

.ArticleContainer figure {
    text-align: center;
}

.presentation_block table, th, td{
    border: none!important;    
}

.presentation_block table{
    width: 100%;
}

.presentation_block tr:first-child td:first-child{
    width: 50%;
}

.presentation_block tr:first-child td:last-child img:first-child{
    width: 50%;
    height: auto;
}

/*Mobil*/
.ArticleContainerMobil {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    color: #666;
    line-height: 1.9em;
    font-weight: 400;
}

.ArticleContainerMobil h1 {
    color: black;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-left: 0.5rem;
}

.ArticleMetaMobil {
    margin-left: 0.5rem;
}

.wp-block-table {
    width: 100%;
}

.wp-block-table {
    width: 100%;
    margin: 0 0 0 0;
}

.wp-block-table table,
th,
td {
    border: solid 1px #666;
    border-collapse: collapse;
    text-align: center;
}

.ArticleContent p {
    width: 100%;
    padding: 0 0.5rem 0 0.5rem;
    font-size: 1rem;
}

.ArticleContent h2,
.ArticleContent h3 {
    margin-left: 0.5rem;
}

.ArticleFeaturedImageMobil {
    width: 90%;
    margin: auto auto;
    box-shadow: 0.1rem 0.3rem 0.8rem 0 #222222;
}

.ArticleContent img {
    width: 100%;
    height: auto;
}